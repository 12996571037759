import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Fab } from '@mui/material';
import { m } from 'framer-motion';
import { forwardRef } from 'react';
const FabButtonAnimate = forwardRef(({ size = 'large', children, sx, sxWrap, ...other }, ref) => (_jsx(AnimateWrap, { size: size, sxWrap: sxWrap, children: _jsx(Fab, { ref: ref, size: size, sx: sx, ...other, children: children }) })));
export default FabButtonAnimate;
const varSmall = {
    hover: { scale: 1.07 },
    tap: { scale: 0.97 },
};
const varMedium = {
    hover: { scale: 1.06 },
    tap: { scale: 0.98 },
};
const varLarge = {
    hover: { scale: 1.05 },
    tap: { scale: 0.99 },
};
function AnimateWrap({ size, children, sxWrap }) {
    const isSmall = size === 'small';
    const isLarge = size === 'large';
    return (_jsx(Box, { component: m.div, whileTap: "tap", whileHover: "hover", variants: (isSmall && varSmall) || (isLarge && varLarge) || varMedium, sx: {
            display: 'inline-flex',
            ...sxWrap,
        }, children: children }));
}
